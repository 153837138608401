import React from 'react';
import "./style.css"

const BioSection = (props) => (
  <div class="mt-5" >
    <h5 class="mb-4 bio-section-title">{props.data.title}</h5>
    {props.data.items.map((data, key) => {
      return (
        <div key={key}>
          <p class="bio-section-item">{data}</p>
        </div>
      );
    })}

  </div>
);

export default BioSection;