import React from 'react';
import DocumentMeta from 'react-document-meta';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './curriculumVitae';
import * as serviceWorker from './serviceWorker';

const meta = {
  title: 'Vuk Ilic Resume',
  description: 'This is a resume of Vuk Ilic',
  author: "Vuk Ilic"
}

class Document extends React.Component {


  render() {
    return (
      <React.StrictMode>
        <DocumentMeta {...meta}>
          <App />
        </DocumentMeta>
      </React.StrictMode>
    )
  }
}

ReactDOM.render(
  <Document />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
