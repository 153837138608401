export const cvIntro = {
   "intro": "Software engineer with 10+ years of experience developing robust code in areas of Video Processing, Video Analytics and Embedded. Experienced in delivering highly optimized solutions leveraging the power of the GPUs as well as embedded processors for real-time applications in Video Processing and GNSS respectively. Enjoying work on cutting edge technologies with a deep understanding of underlying principles."
}

export const cvData = [
   {
      "section": "EXPERIANCE",
      "icon" : "exp",
      "items": [
         {
            "title": "Software Engineer at Leica Geosystems",
            "date": "08/2019 -",
            "description": "Performing roles of Tech Lead and Integration expert while working on the development of ARM-based embedded platform for Leica GNSS Reference Station with focus on reliability and high precision. Involved in maintaining existing WindowsCE based platform as well as helping shape the architecture of next-generation Yocto based platform.",
            "technologies": "C++ / Python / WindowsCE / Yocto"
         },
         {
            "title": "Software Engineer at Advertima AG, St. Gallen",
            "date": "11/2016 - 5/2019",
            "description": "Engaged in the field of digital real-world marketing. Working on Computer Vision tasks including Extrinsic Multi-camera Calibration and real-time multi-object tracking as well as Deep Learning for a purpose of Face Pose Estimation. Responsible for Store Analytics Feature.",
            "technologies": "C++ / Python / Linux"
         },
         {
            "title": "Computer Vision Engineer at RT-RK",
            "date": "08/2016 - 10/2016",
            "description": "Worked on rear-view mirror object detection and warning system as well as driver drowsiness awareness while implementing it on embedded ADAS board based on TI microprocessor. Mentored a team of graduate students.",
            "technologies": "C / C++"
         },
         {
            "title": "Research Engineer at MotionDSP",
            "date": "06/2011 - 07/2016",
            "description": "Worked on flagship product “Ikena” specifically on Video Processing and Enhancement algorithms, including pixel level processing, such as Denoising, Deblurring, color and contrast correction. In addition, worked on higher level image analysis and understanding such as Blob Detection and object clustering, and Multiple Object Tracking on aerial videos, as well as Geomosaicing on videos from unmanned aircraft and submarines.",
            "technologies": "C++ / CUDA / Visual Studio / MATLAB"
         }
      ]
   },
   {
      "section": "EDUCATION",
      "icon" : "edu",
      "items": [
         {
            "title": "Bachelor’s Degree",
            "date": "2009 - 2013",
            "description": "Finished four year long B.Sc. program at the Faculty of Electronic Engineering with grade 9.29/10, and acquired diploma “Diplomirani inženjer elektronike i računarstva za računarstvo i informatiku” (Graduated engineer in the area of electronics specializing in informatics and computer science).",
         }
      ]
   },
   {
      "section": "PATENT",
      "icon" : "patent",
      "items": [
         {
            "title": "Patent no. US009898677B1",
            "date": "2018",
            "description": "Worked on “Object-level grouping and identification for tracking objects in a video” as one of the co-inventors during employment in MotionDSP.",
         }
      ]
   }
]

export const cvBio = [
   {
      "title": "LANGUAGES",
      "items": [
         "Serbian - Native",
         "English - C1 Level",
         "German - B1 Level"
      ]
   },
   {
      "title": "CONTACT",
      "items": [
         "Heerbrugg, Switzerland",
         "+41 76 432 2048",
         "contact@ilicvuk.com",
         "ilicvuk.com"
      ]
   }
]