import "@fontsource/raleway/300.css"
import "@fontsource/raleway/400.css"
import "@fontsource/raleway/500.css"
import "@fontsource/raleway/600.css"
import "@fontsource/raleway/700.css"

import React from 'react';
import portrait from './portrait-small.svg'
import edu from './edu.svg';
import exp from './exp.svg';
import patent from './patent.svg';
import name from './name.svg'
import './App.css';
import Section from './section';
import BioSection from './bioSection';
import { cvData } from "./data";
import { cvBio } from "./data";
import { cvIntro } from "./data";

var icons = {}
icons["edu"] = edu;
icons["exp"] = exp;
icons["patent"] = patent;

function App() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="mb-5">
            <img src={portrait} className="logo" alt="logo" />
          </div>
          <div>
            <p class="skill">C/C++</p>
            <p class="skill">Python</p>
            <p class="skill">Linux</p>
            <p class="skill">MATLAB</p>
            <p class="skill">OpenCV</p>
            <p class="skill">CUDA</p>
          </div>

          {cvBio.map((data, key) => {
            return (
              <div key={key}>
                <BioSection data={data}></BioSection>
              </div>
            );
          })}

        </div>
        <div class="col-md-8 mt-5">
          <div>
            <div className="text-right">
              <img src={name} className="logo-right" alt="logo" />
            </div>
            <div className="row">
              <div className="col-sm-0 col-md-1 "> </div>
              <div className="col-sm-12 col-md-11 short-bio">{cvIntro.intro}</div>
            </div>
          </div>
          <div>
            {cvData.map((data, key) => {
              return (
                <div key={key}>
                  <Section data={data} icon={icons[data.icon]}></Section>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
