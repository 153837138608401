import React from 'react';
import "./style.css"

const Item = (props) => (
    <div className="mt-3 item-body">
        <div className="row">
            <div className="col-8">
                <p className="item-title">{props.data.title}</p>
            </div>
            <div className="col-4">
                <p className="date">{props.data.date}</p>
            </div>
        </div>

        <p class="description">{props.data.description}</p>
        <h6 className="technologies">{props.data.technologies}</h6>
    </div>

);

export default Item;