import React from 'react';
import "./style.css"
import Item from "./item"

const Section = (props) => (
    <div className=" mt-4">
        <div className="row ">
            <div className="col-1 icon-div">
                <img src={props.icon} className="icon " alt="logo" />
            </div>
            <div className="col-11">
                <p className="title mb-0">{props.data.section}</p>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-0 col-md-1 "> </div>
            <div className="col-sm-12 col-md-11">
                {props.data.items.map((data, key) => {
                    return (
                        <div key={key}>
                            <Item data={data}></Item>
                        </div>
                    );
                })}
            </div>
        </div>
    </div>
);

export default Section;